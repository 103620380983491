import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Head from 'components/head';
import Layout from 'components/layout';
import BlogReel from 'components/sections/blogReel';

import MEDIA from 'helpers/mediaTemplates';

import Section from '~v2/section';
import Wrapper from '~v2/elements/container/wrapper';
import Title from '~v2/elements/title';
import { TitleContainer } from '~v2/elements/title/title.css';

const Blog = ({
  data: {
    blogJson: { title, header, left_rail_bg },
    allWordpressPost: { nodes: posts },
  },
}) => {
  const blogPosts = posts.map(post => {
    return {
      title: post.title,
      date: post.date,
      slug: `/blog/${post.slug}`,
      excerpt: post.excerpt,
      featured_media: post.featured_media ? post.featured_media.localFile : {},
    };
  });

  return (
    <Layout>
      <Head
        pageTitle={title}
        pageDescription="The Trace team never sleeps. Check out our blog to stay apprised of how we’re elevating the hemp industry."
      />
      <Wrapper>
        <TitleContainer>
          <Title as="h1">{header.title}</Title>
        </TitleContainer>
        <Section
          boundsProps={{
            fluid: true,
            style: { width: '100%' },
            DESKTOP_DOWN: { padding: '0 2rem' },
            DESKTOP: { padding: '0 2rem' },
          }}
          left={{
            boxProps: {
              fluid: true,
              TABLET_DOWN: { width: '0%' },
              TABLET: { width: '5%' },
              DESKTOP_UP: { width: '15%' },
            },
            backgroundProps: {
              image: left_rail_bg,
            },
          }}
          right={{
            children: (
              <BlogReelContainer>
                <BlogReel title={false} posts={blogPosts} />
              </BlogReelContainer>
            ),
            boxProps: {
              fluid: true,
              PHONE: { padding: '0' },
              TABLET_DOWN: { width: '100%', padding: '0 2rem' },
              TABLET: { width: '95%' },
              DESKTOP_UP: { width: '85%' },
            },
          }}
        />
      </Wrapper>
    </Layout>
  );
};

Blog.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Blog;

const BlogReelContainer = styled.div`
  ${MEDIA.MIN_DESKTOP`
    margin-left: 1.8rem;
  `};
`;

export const query = graphql`
  query BlogQuery {
    blogJson {
      title
      header {
        title
      }
      left_rail_bg {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    allWordpressPost(
      sort: { fields: date, order: DESC }
      filter: { status: { eq: "publish" } }
    ) {
      nodes {
        date(formatString: "YYYY-MM-DD")
        modified(formatString: "MMMM DD, YYYY")
        title
        slug
        excerpt
        featured_media {
          localFile {
            childImageSharp {
              fluid(maxHeight: 300, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
